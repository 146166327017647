import { isEqual } from 'lodash'
import { useCallback, useState } from 'react'
import { isDefined } from 'utils'

import { useEffectOnce } from './use-effect-once'

const isObject = (value: unknown): value is Record<string, unknown> => {
  return value !== null && typeof value === 'object'
}

const isArray = (value: unknown): value is Array<unknown> => {
  return Array.isArray(value)
}

const getQueryParams = () => {
  return Object.fromEntries(new URLSearchParams(window.location.search))
}

export const parseQueryParam = (value?: string) => {
  if (!isDefined(value)) {
    return
  }
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

type SetState<V> = (value: V) => void

type QueryValue = string | number | boolean | Array<unknown> | Record<string, unknown>

interface Props<T> {
  key: string
  parse: (value: string) => T
}

export function useQueryParamState<V extends QueryValue>(props: Props<V>): [V | undefined, SetState<V>]
export function useQueryParamState<V extends QueryValue>(props: Props<V> & { defaultValue: V }): [V, SetState<V>]
export function useQueryParamState<V extends QueryValue>(props: string): [V | undefined, SetState<V>]
export function useQueryParamState<V extends QueryValue>(props: string | (Props<V> & { defaultValue?: V })) {
  if (typeof props === 'string') {
    props = { key: props, parse: parseQueryParam }
  }
  const { key, defaultValue, parse = parseQueryParam } = props
  /**
   * Ideally we should be using `shallow` from `useRouter`, (https://nextjs.org/docs/pages/building-your-application/routing/linking-and-navigating#shallow-routing)
   * but it's seems to be broken our current Next.js version 13.5.4. (https://github.com/vercel/next.js/discussions/48110) as it triggers getServerSideProps and all the react tree is re-rendered
   * What is the solution? Try bumping next to a newer version (13.5.5 or 13.5.6) or even try next 14 as the last 13.x.x version is 13.5.6
   * I tried upgrading to 13.5.6 but it didn't work as some Redux store error was thrown
   */
  const [value, setValue] = useState<V>(() => {
    const queryParams = getQueryParams()
    const value = queryParams[key]
    return parse(value) ?? defaultValue
  })

  const setState = useCallback<SetState<V>>(
    (newValue) => {
      const currentQueryParams = getQueryParams()
      const newQuery: Record<string, string> = {
        ...currentQueryParams,
        [key]: isArray(newValue) || isObject(newValue) ? JSON.stringify(newValue) : newValue.toString(),
      }
      const isValueEmpty = !Boolean(newValue) || (isArray(newValue) && !Boolean(newValue.length))
      if (isValueEmpty) {
        delete newQuery[key]
      }
      const isNewQueryEmpty = Object.keys(newQuery).length === 0
      const newUrl = isNewQueryEmpty
        ? window.location.pathname
        : `${window.location.pathname}?${new URLSearchParams(newQuery).toString()}`
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
      setValue(newValue)
    },
    [key]
  )

  useEffectOnce(() => {
    const queryParams = getQueryParams()
    const value = queryParams[key]
    if (
      (isDefined(parse(value)) && !isEqual(parse(value), value)) ||
      (!isDefined(parse(value)) && !isEqual(value, defaultValue))
    ) {
      setValue(parse(value) ?? defaultValue)
    }
  })

  return [value, setState]
}
