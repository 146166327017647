import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { textStyles } from '../text-styles'

const helpers = createMultiStyleConfigHelpers(['box'])

export const DateRangeInput = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      '& .rdrMonthAndYearPickers select': {
        ...textStyles['title-md'],
      },
      '& .rdrMonthAndYearPickers': {
        ...textStyles['title-md'],
      },
      '& .rdrDateDisplayWrapper': {
        backgroundColor: 'transparent',
        margin: '0 !important',
      },
      '& .rdrDateDisplayItem input': {
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'basic.white',
        position: 'relative',
        zIndex: 1,
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        height: '5',
        pt: '1',
        width: '100%',
        color: 'text-primary',
        ...textStyles['paragraph-lg'],
      },
      '& .rdrDateDisplay': {
        borderY: '1px solid',
        borderColor: 'border-soft',
        padding: '3',
      },
      '& .rdrDateInput': {
        border: 'none',
        boxShadow: 'none',
        textAlign: 'left',
        borderRight: '1px solid',
        borderColor: 'border-soft',
        '&:before': {
          content: '"FROM"',
          ...textStyles['subheading-sm'],
          color: 'text-disabled',
        },
        '&:last-of-type': {
          border: 'none',
          '&:before': {
            content: '"TO"',
          },
        },
      },
      '& .rdrNextPrevButton': {
        borderRadius: 'lg',
        bg: 'bg-inverse',
      },
      '& .rdrPprevButton i': {
        borderRightColor: 'icon-inverse',
      },
      '& .rdrNextButton i': {
        borderLeftColor: 'icon-inverse',
      },
      '& .rdrInRange, .rdrStartEdge, .rdrEndEdge': {
        padding: '2',
        marginX: '1',
        borderRadius: 'sm',
        color: 'icon-info !important',
      },
      '& .rdrDayStartPreview': {
        borderLeftRadius: '8px',
        color: 'icon-info !important',
      },
      '& .rdrDayEndPreview': {
        borderRightRadius: '8px',
        color: 'icon-info !important',
      },
      '& .rdrDayNumber .rdrInRange': {
        color: 'text-inverse',
      },
    },
  },
  variants: {
    disabled: {
      box: {
        cursor: 'not-allowed',
        '& *': {
          cursor: 'not-allowed',
        },
      },
    },
  },
})
