import moment from 'moment'
import { useSession } from 'next-auth/react'
import { Icon, Tag } from 'ui-lib'

import { CapitalApprovalVersion, useCapitalApprovalQuery } from '@/gql'

export const NewTag = () => {
  const { data: dataApproval } = useCapitalApprovalQuery()
  const isCapitalPricingVersionV2 = dataApproval?.capitalApproval?.pricingVersion === CapitalApprovalVersion.V2

  const { data: session } = useSession()
  const isRecentOnboarding = moment().diff(session?.user.internal?.onboardedAt, 'days') <= 30

  if (!isCapitalPricingVersionV2 || !isRecentOnboarding) {
    return null
  }

  return (
    <Tag variant="blue" size="sm" rounded="full" fontWeight="semibold">
      New <Icon icon="sparkles" w="3" h="3" ml="1" variant="inverse" />
    </Tag>
  )
}
