import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(['filledTrack'])

const baseStyle = definePartsStyle({})

export const Progress = defineMultiStyleConfig({
  baseStyle,
  variants: {
    primary: {
      filledTrack: {
        backgroundColor: 'icon-primary',
      },
    },
    blueGreen: {
      filledTrack: {
        background: 'linear-gradient(236deg, #576CDB 0%, #64C774 100%)',
      },
    },
  },
})
