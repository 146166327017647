import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Box, Checkbox, Flex, Form, Text } from 'ui-lib'
import { BLOCKED_COUNTRIES_FOR_SHIPPING, isDefined } from 'utils'
import { object, string } from 'yup'

import { COUNTRIES_OPTIONS, DEFAULT_COUNTRY_OPTION } from '@/src/constants/countries'
import { Option, PROVINCES_OPTIONS } from '@/src/constants/provinces'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { addressValidator, NOT_JUST_EMPTY_SPACE_REGEX } from '@/src/utils/validator'
import { GenericAddressData } from '@/types/form-info'

import { ActionButtons } from '../action-buttons'
import { useFillableAddress } from './use-fillable-address'

export const ALLOWED_COUNTRIES_FOR_SHIPPING = COUNTRIES_OPTIONS.filter(
  (country) => !BLOCKED_COUNTRIES_FOR_SHIPPING.includes(country.value)
)

const resolver = yupResolver(
  addressValidator.pick(['city', 'country', 'postalCode', 'state']).concat(
    object({
      addressLine1: string().required('Address is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Address is required'),
      addressLine2: string(),
    })
  )
)

interface Props {
  initialValues?: GenericAddressData
  onSave: (values: GenericAddressData) => void
  onCancel?: () => void
  onUseFillableAddressClick?: (isChecked: boolean) => void
  isPrefilled?: boolean
  preFillableAddress?: GenericAddressData
  fillableAddressLabel?: string
  submitButtonLabel?: string
  isCompactStyle?: boolean
  isSubmitting?: boolean
}

export const ShippingAddressForm = ({
  initialValues,
  onSave,
  onCancel,
  onUseFillableAddressClick,
  isPrefilled = false,
  preFillableAddress,
  fillableAddressLabel,
  submitButtonLabel = 'Save',
  isCompactStyle = false,
  isSubmitting = false,
}: Props) => {
  const canChangeCountry = useFeatureGate(StatsigFeatureGate.OVERSEAS_ADDRESS)

  const { defaultAddress, fillableAddress, isFilled, setIsFilled } = useFillableAddress(
    isPrefilled,
    preFillableAddress,
    initialValues
  )

  const currentInitialValues = Boolean(isFilled) ? fillableAddress : defaultAddress

  const form = useForm<GenericAddressData>({
    defaultValues: currentInitialValues,
    resolver,
  })

  const handleChangeFillableAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    const toSetAddress = isChecked ? fillableAddress! : defaultAddress // fillableAddress is defined if checkbox is active
    form.reset(toSetAddress)
    setIsFilled(isChecked)
    onUseFillableAddressClick?.(isChecked)
  }

  return (
    <>
      {isDefined(fillableAddress) && (
        <Flex mb="5">
          <Checkbox
            id="isFilled"
            name="isFilled"
            size="lg"
            isChecked={isFilled}
            onChange={handleChangeFillableAddress}
          />
          <Text textStyle="paragraph-lg" fontWeight="medium" textColor="text-primary" ml="3">
            Send this card to my {fillableAddressLabel} address
          </Text>
        </Flex>
      )}
      <Box width="full">
        <Form
          display="flex"
          flexDirection="column"
          width="full"
          gap={isCompactStyle ? '3' : '6'}
          pb={isCompactStyle ? '0' : '8'}
          onSubmit={form.handleSubmit(onSave)}
        >
          <Flex flexDirection={isCompactStyle ? 'row' : 'column'} gap={isCompactStyle ? '3' : '6'}>
            <Form.Field
              id="addressLine1"
              label="Address Line 1"
              errorMessage={form.formState.errors.addressLine1?.message}
            >
              <Form.Input
                autoComplete="shipping address-line1"
                aria-autocomplete="inline"
                placeholder="Address Line 1"
                disabled={isFilled}
                {...form.register('addressLine1')}
              />
            </Form.Field>
            <Form.Field
              id="addressLine2"
              label="Address Line 2"
              errorMessage={form.formState.errors.addressLine2?.message}
            >
              <Form.Input
                autoComplete="shipping address-line1"
                aria-autocomplete="inline"
                placeholder="Address Line 2"
                disabled={isFilled}
                {...form.register('addressLine2')}
              />
            </Form.Field>
          </Flex>

          <Form.Field id="city" label="City" errorMessage={form.formState.errors.city?.message}>
            <Form.Input placeholder="City" disabled={isFilled} {...form.register('city')} />
          </Form.Field>
          <Flex gap="4">
            <Form.Field id="postalCode" label="Postal Code" errorMessage={form.formState.errors.postalCode?.message}>
              <Form.Input
                autoComplete="shipping postal-code"
                aria-autocomplete="inline"
                placeholder="Postal Code"
                disabled={isFilled}
                {...form.register('postalCode')}
              />
            </Form.Field>

            {!canChangeCountry || form.getValues().country === 'CAN' ? (
              <Controller
                control={form.control}
                name="state"
                render={({ field }) => {
                  return (
                    <Form.Field id="state" label="state/province" errorMessage={form.formState.errors.state?.message}>
                      <Form.Select
                        placeholder="Select state"
                        value={PROVINCES_OPTIONS.find((val) => val.value === field.value)}
                        options={PROVINCES_OPTIONS}
                        onChange={(option) => {
                          const value = (option as Option).value
                          return field.onChange(value)
                        }}
                        isDisabled={isFilled}
                        isClearable={false}
                      />
                    </Form.Field>
                  )
                }}
              />
            ) : (
              <Form.Field id="state" label="state/province" errorMessage={form.formState.errors.state?.message}>
                <Form.Input placeholder="State" disabled={isFilled} {...form.register('state')} />
              </Form.Field>
            )}
          </Flex>
          <Controller
            control={form.control}
            name="country"
            render={({ field }) => {
              return (
                <Form.Field id="country" label="Country" errorMessage={form.formState.errors.country?.message}>
                  <Form.Select
                    placeholder="Select country"
                    defaultValue={DEFAULT_COUNTRY_OPTION}
                    value={ALLOWED_COUNTRIES_FOR_SHIPPING.find((val) => val.value === field.value)}
                    options={ALLOWED_COUNTRIES_FOR_SHIPPING}
                    onChange={(option) => {
                      const value = (option as Option).value
                      field.onChange(value)
                      form.trigger('country')
                      form.setValue('state', '')
                    }}
                    isDisabled={isFilled === true || !canChangeCountry}
                    isClearable={false}
                  />
                </Form.Field>
              )
            }}
          />

          <ActionButtons pt={isCompactStyle ? '0' : '4'}>
            {isDefined(onCancel) && (
              <ActionButtons.Secondary onClick={onCancel} isDisabled={form.formState.isSubmitting || isSubmitting}>
                Back
              </ActionButtons.Secondary>
            )}
            <ActionButtons.Primary isLoading={form.formState.isSubmitting || isSubmitting} type="submit">
              {submitButtonLabel}
            </ActionButtons.Primary>
          </ActionButtons>
        </Form>
      </Box>
    </>
  )
}
