import { can } from '@try-keep/auth'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { Banner, isDefined, Link, Tooltip } from 'ui-lib'
import { Money, pluralize } from 'utils'

import { CapitalApprovalVersion, useCapitalApprovalQuery, useGetCapitalInfoQuery } from '@/gql'
import { FinancialAppEvent, SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { StatsigFeatureGate, useConfirmAction, useFeatureGate } from '@/src/contexts/misc'
import { useHasCapitalFundings } from '@/src/hooks/capital'
import { useInternalUser, useServerTrackEvent } from '@/src/hooks/misc'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'
import { date } from '@/src/utils/date'
import { useInternalUserStatus } from '@/src/utils/user-session'

enum Status {
  'ON_TIME' = 'on-time',
  'CRITICAL' = 'critical',
  'EXPIRED' = 'expired',
  'PROCESSING' = 'processing',
  'REJECTED' = 'rejected',
}

type BannerConfig = {
  [key in Status]: {
    image: string
    headline: string
    content: string | ReactNode
    tag?: string
    variant: 'success' | 'error' | 'warning' | 'info'
    buttonLabel?: string
    onGetStarted?: () => void
  }
}

const confirmOptions = {
  img: {
    src: '/capital/bank-connection.svg',
    alt: 'Keep - Bank connection icon',
  },
  title: 'Reconnect your bank account',
  description:
    "Your bank data is over 30 days old. Let's securely connect your bank account to help us determine your Capital credit limit again. Continue?",
  primaryAction: {
    label: 'Yes, continue',
  },
}

const getStatus = (daysLeft: number, underwritingStatus: string | undefined) => {
  if (underwritingStatus === 'PENDING') {
    return Status.PROCESSING
  }
  if (underwritingStatus === 'REJECTED') {
    return Status.REJECTED
  }
  if (daysLeft <= 0) {
    return Status.EXPIRED
  }
  if (daysLeft <= 3) {
    return Status.CRITICAL
  }
  return Status.ON_TIME
}

export const UnderwritingBanner = () => {
  const router = useRouter()
  const user = useInternalUser()
  const confirm = useConfirmAction()
  const { data } = useGetCapitalInfoQuery()
  const trackServerEvent = useServerTrackEvent()
  const { hasFundings } = useHasCapitalFundings()
  const { userBusinessIsActive } = useInternalUserStatus()
  const { isCapitalBlocked } = useInternalUserStatus()

  const { data: dataApproval } = useCapitalApprovalQuery()
  const isCapitalPricingVersionV2 = dataApproval?.capitalApproval?.pricingVersion === CapitalApprovalVersion.V2

  const isUnderwritingActive = useFeatureGate(StatsigFeatureGate.CAPITAL_UNDERWRITING) && userBusinessIsActive

  const underwrittenDate = user?.business?.underwrittenAt

  if (!isDefined(underwrittenDate) || !isUnderwritingActive) {
    return null
  }

  const expirationDate = date(underwrittenDate).add(30, 'days').toDate()
  const daysLeft = date().diff(expirationDate, 'days') * -1 + 1
  const expirationDateFormatted = date(expirationDate).format('MMMM DD, YYYY')
  const underwritingStatus = data?.capitalInfo?.underwritingStatus
  const creditLimit = data?.capitalInfo?.capitalCreditLimit ?? '$0'

  const status = getStatus(daysLeft, underwritingStatus)
  const shouldShowExpired = status !== Status.PROCESSING && status !== Status.REJECTED

  const payload: FinancialAppEvent['payload'] = {
    team: 'Capital',
    page: 'Capital Landing Screen',
    request_type: 'new',
    version: isCapitalPricingVersionV2 ? 'v2' : 'v1',
    capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
  }

  const onGetStarted = () => {
    const getCapitalLink = isCapitalPricingVersionV2 ? '/capital/v2/get-capital' : '/capital/apply'
    trackServerEvent({
      type: SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED,
      payload,
    })
    router.push(getCapitalLink)
  }

  const connectBank = () => {
    trackServerEvent({
      type: SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED,
      payload,
    })

    confirm(confirmOptions).then((result) => {
      if (Boolean(result)) {
        trackServerEvent({
          type: SEGMENT_PAGE_VISITED.BANK_CONNECTION_STARTED,
          payload,
        })

        router.push('/bank-connection/flinks-connection?redirect=/capital/successful-bank-connection')
      }
    })
  }

  const bannerVariant: BannerConfig = {
    [Status.ON_TIME]: {
      image: '/capital/clock.jpg',
      headline: `Your credit limit of ${creditLimit} is valid until `,
      content: 'Don’t miss out! Get started now to receive funds in your Keep CAD account within 24 hours!',
      tag: `Expires in ${pluralize(daysLeft, 'day')}`,
      variant: 'success',
      buttonLabel: hasFundings ? 'New capital' : 'Get started',
      onGetStarted,
    },
    [Status.CRITICAL]: {
      image: '/capital/clock-error.png',
      headline: `Your credit limit of ${creditLimit} is about to expire on `,
      content: 'Act fast and receive funds within 24 hours.',
      tag: `Expires in ${pluralize(daysLeft, 'day')}`,
      variant: 'error',
      buttonLabel: hasFundings ? 'New capital' : 'Get started',
      onGetStarted,
    },
    [Status.EXPIRED]: {
      image: '/capital/clock-warning.png',
      headline: `Your credit limit of ${creditLimit} expired on `,
      content: 'Get started now to see if you are still eligible.',
      tag: `Expired ${pluralize(daysLeft * -1, 'day')} ago`,
      variant: 'warning',
      onGetStarted: connectBank,
    },
    [Status.PROCESSING]: {
      image: '/capital/clock-info.png',
      headline: `Your request for Capital is in process.`,
      content: 'You’ll be notified of the approval soon.',
      variant: 'info',
    },
    [Status.REJECTED]: {
      image: '/capital/clock.jpg',
      headline: `You could get approved for credit limit of up to $200,000 with Keep Capital.`,
      content: (
        <>
          Don’t miss out! Write to us at
          <Link href="mailto:support@trykeep.com" color="text-info" mx="1" textDecor="underline">
            support@trykeep.com
          </Link>
          to get your offer.
        </>
      ),
      variant: 'success',
    },
  }

  const tag = bannerVariant[status].tag
  const getStarted = bannerVariant[status].onGetStarted
  const hasAvailableCredit =
    Money.fromFormattedCurrencyString(data?.capitalInfo?.availableCreditLimit ?? '0').toNumber() > 0

  const conditions = [!hasAvailableCredit, isCapitalBlocked]
  const isGetStartedButtonDisabled = conditions.some(Boolean)

  return (
    <Banner size="sm" variant={bannerVariant[status].variant}>
      <Banner.Image src={bannerVariant[status].image} />
      <Banner.Headline>
        {bannerVariant[status].headline} {shouldShowExpired && <strong>{expirationDateFormatted}</strong>}
      </Banner.Headline>
      <Banner.Content>{bannerVariant[status].content}</Banner.Content>
      {isDefined(tag) && <Banner.Tag>{tag.toUpperCase()}</Banner.Tag>}
      {isDefined(getStarted) && can(user, 'create', 'Capital') && (
        <Banner.Button size="sm" onClick={getStarted} isDisabled={isGetStartedButtonDisabled}>
          <Tooltip
            label="You've fully utilized your available financing limit. The limit will reset after your next monthly repayment."
            isDisabled={hasAvailableCredit}
          >
            {bannerVariant[status].buttonLabel ?? 'Get started'}
          </Tooltip>
        </Banner.Button>
      )}
    </Banner>
  )
}
