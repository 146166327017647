import { isAccountant } from '@try-keep/auth'
import { StatsigFeatureGate } from '@try-keep/feature-flag'
import { CCAccessStatus, UserType } from 'db-schema'
import { DEFAULT_CURRENCY_CLOUD_FLAGS } from 'services/src/currencyCloud/featureFlags'
import { KeepFeatureFlag, KeepFeatureFlags } from 'services/src/featureFlag/featureFlags'
import { BusinessWithFeatureFlags } from 'services/src/featureFlag/types'
import { Box, GiftIcon, Icon } from 'ui-lib'

import BankIcon from '@/src/svgs/BankIcon'
import CapitalIcon from '@/src/svgs/CapitalIcon'
import CardIcon from '@/src/svgs/CardIcon'
import HomeIcon from '@/src/svgs/HomeIcon'
import PaymentsIcon from '@/src/svgs/PaymentsIcon'
import RewardsIcon from '@/src/svgs/RewardsIcon'
import SettingsIcon from '@/src/svgs/SettingsIcon'
import TransactionsIcon from '@/src/svgs/TransactionsIcon'
import isDefined from '@/src/utils/dataShaping/isDefined'

import { NewTag } from '../components/capital'
import { isOwnerUser } from '../utils/misc'

export type PageName =
  | 'home'
  | 'transactions'
  | 'application'
  | 'move-funds'
  | 'move-funds-wire-details'
  | 'move-funds-withdraw'
  | 'move-funds-review'
  | 'move-funds-initiated'
  | 'onboarding'
  | 'cashback'
  | 'card-test'
  | 'convert-currency'
  | 'convert-currency-details'
  | 'convert-currency-completed'
  | 'bank-transfer-select-recipient'
  | 'bank-transfer-confirm-recipient'
  | 'bank-transfer-payment-type'
  | 'bank-transfer-amount'
  | 'bank-transfer-review'
  | 'bank-transfer-initiated'
  | 'plaid-test'
  | 'my-profile'
  | 'identity'
  | 'banking'
  | 'cards'
  | 'rewards'
  | 'settings'
  | 'settings-accounting'
  | 'settings-personal'
  | 'settings-business'
  | 'settings-team'
  | 'settings-security'
  | 'settings-notifications'
  | 'settings-statements'
  | 'settings-statement-extension'
  | 'payments'
  | 'payments-extension'
  | 'capital'
  | 'capital-apply'
  | 'capital-plan'
  | 'capital-confirmation'
  | 'capital-success'
  | 'employee-invitation-cha'
  | 'employee-invitation-set-password'
  | 'employee-invitation-personal-details'
  | 'employee-invitation-home-address'
  | 'employee-invitation-id-validation'
  | 'statement-extension'
  | 'statement-extension-autoenrollment-plan'
  | 'statement-extension-autoenrollment-review'
  | 'statement-extension-autoenrollment-success'
  | 'referrals'
  | 'accounting'

export type PageType = {
  /**
   * Friendly name
   */
  section: string
  /**
   * NextJS Route
   */
  route: string
  /**
   * Internal ID for routing
   */
  page: PageName
  /**
   * Icon
   */
  icon?: JSX.Element
  /**
   * dropdown
   */
  dropdown?:
    | ((
        business?: BusinessWithFeatureFlags | undefined | null,
        userType?: UserType | undefined | null
      ) => Promise<JSX.Element | SubPageType[] | null>)
    | null
  /**
   * Is gonna allow to use the principal button as a menu link if exist a dropdown array
   */
  isDropdownLink?: boolean
  /**
   * Feature flags required to enable the page
   */
  featureFlags?: KeepFeatureFlag[] | StatsigFeatureGate[]
  /**
   * Access status that enable access to the page
   */
  accessStatus?: CCAccessStatus[]
  /**
   * Default dropdown open
   */
  defaultDropdownOpen?: boolean
  /**
   * If any element needs to be added at the right
   */
  rightElement?: JSX.Element
}

export type SubPageType = {
  /**
   * Friendly name
   */
  section: string
  /**
   * NextJS Route
   */
  route: string
  /**
   * Internal ID for routing
   */
  page: PageName
}

export const AUTOENROLLMENT_PAGES: PageType[] = [
  {
    section: 'Statement Extension',
    page: 'statement-extension',
    route: '/statement-extension',
  },
]

export const PAGES: PageType[] = [
  {
    section: 'Home',
    route: '/',
    page: 'home',
    icon: <HomeIcon />,
  },
  {
    section: 'Transactions',
    route: '/transactions',
    page: 'transactions',
    icon: <TransactionsIcon />,
  },
  {
    section: 'Rewards',
    route: '/rewards',
    page: 'rewards',
    icon: <RewardsIcon />,
  },
  {
    section: 'Cards',
    route: '/cards',
    page: 'cards',
    icon: <CardIcon />,
  },
  {
    section: 'Banking',
    route: '/banking',
    page: 'banking',
    icon: <BankIcon />,
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS],
    accessStatus: [
      CCAccessStatus.NOT_REQUESTED,
      CCAccessStatus.REQUESTED,
      CCAccessStatus.IN_REVIEW,
      CCAccessStatus.APPROVED,
    ],
  },
  {
    section: 'Payments',
    route: '/payments',
    page: 'payments',
    icon: <PaymentsIcon />,
    isDropdownLink: true,
    defaultDropdownOpen: true,
    // This is function is going to be called event if you add a new function in ACCOUNTANT_PAGES
    dropdown: async (business: BusinessWithFeatureFlags | undefined | null, userType: UserType | null | undefined) => {
      if (!isDefined(business)) {
        return null
      }
      const isOwnerOrAdminAccount = isOwnerUser({ status: 'ACTIVE', userType })
      const isAccountantUser = isAccountant({ status: 'ACTIVE', userType: userType as UserType })

      if (isOwnerOrAdminAccount || isAccountantUser) {
        return AUTOENROLLMENT_PAGES
      }

      return null
    },
  },
  {
    section: 'Capital',
    route: '/capital',
    page: 'capital',
    icon: <CapitalIcon />,
    rightElement: <NewTag />,
  },
  {
    section: 'Accounting',
    route: '/accounting',
    page: 'accounting',
    icon: (
      <Box w="5">
        <Icon icon="book" variant="inherit" />
      </Box>
    ),
    featureFlags: [StatsigFeatureGate.RUTTER_INTEGRATION],
  },
  {
    section: 'Referrals',
    route: '/refer',
    page: 'referrals',
    icon: <GiftIcon />,
    featureFlags: [StatsigFeatureGate.REFERRALS],
  },
  {
    section: 'Settings',
    route: '/settings',
    page: 'settings',
    icon: <SettingsIcon />,
  },
]

export const SETTINGS_PAGES: PageType[] = [
  {
    section: 'Personal information',
    route: '/settings/personal',
    page: 'settings-personal',
  },
  {
    section: 'Business information',
    route: '/settings/business',
    page: 'settings-business',
  },
  {
    section: 'Notifications',
    route: '/settings/notifications',
    page: 'settings-notifications',
  },
  {
    section: 'Statements',
    route: '/settings/statements',
    page: 'settings-statements',
  },
  {
    section: 'Accounting',
    route: '/settings/accounting',
    page: 'settings-accounting',
    featureFlags: [StatsigFeatureGate.RUTTER_INTEGRATION],
  },
  {
    section: 'Team',
    route: '/settings/team',
    page: 'settings-team',
    featureFlags: [KeepFeatureFlags.ACCOUNTANT_ACCESS],
  },
  {
    section: 'Security',
    route: '/settings/security',
    page: 'settings-security',
  },
]

export const CONVERT_CURRENCY_PAGES: PageType[] = [
  {
    section: 'Convert currency',
    route: '/convert-currency',
    page: 'convert-currency',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_CONVERSION],
  },
  {
    section: 'Confirm details',
    route: '/convert-currency/confirm-details',
    page: 'convert-currency-details',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_CONVERSION],
  },
  {
    section: 'Conversion completed',
    route: '/convert-currency/completed',
    page: 'convert-currency-completed',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_CONVERSION],
  },
]

const BANK_TRANSFER_FEATURE_FLAGS = [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_PAYMENT]
export const BANK_TRANSFER_PAGES: PageType[] = [
  {
    section: 'Select recipient',
    route: '/bank-transfer',
    page: 'bank-transfer-select-recipient',
    featureFlags: BANK_TRANSFER_FEATURE_FLAGS,
  },
  {
    section: 'Confirm recipient',
    route: '/bank-transfer/confirm-recipient',
    page: 'bank-transfer-confirm-recipient',
    featureFlags: BANK_TRANSFER_FEATURE_FLAGS,
  },
  {
    section: 'Payment type',
    route: '/bank-transfer/payment-type',
    page: 'bank-transfer-payment-type',
    featureFlags: BANK_TRANSFER_FEATURE_FLAGS,
  },
  {
    section: 'Payment Amount',
    route: '/bank-transfer/amount',
    page: 'bank-transfer-amount',
    featureFlags: BANK_TRANSFER_FEATURE_FLAGS,
  },
  {
    section: 'Payment Review',
    route: '/bank-transfer/review',
    page: 'bank-transfer-review',
    featureFlags: BANK_TRANSFER_FEATURE_FLAGS,
  },
  {
    section: 'Initiated',
    route: '/bank-transfer/initiated',
    page: 'bank-transfer-initiated',
    featureFlags: BANK_TRANSFER_FEATURE_FLAGS,
  },
]

export const MOVE_FUNDS_PAGES: PageType[] = [
  {
    section: 'Move Funds',
    route: '/move-funds',
    page: 'move-funds',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_PAYMENT],
  },
  {
    section: 'Wire Details',
    route: '/move-funds/wire-details',
    page: 'move-funds-wire-details',
    featureFlags: [
      ...DEFAULT_CURRENCY_CLOUD_FLAGS,
      KeepFeatureFlags.CURRENCY_CLOUD_ACCOUNT,
      KeepFeatureFlags.CURRENCY_CLOUD_PAYMENT,
    ],
  },
  {
    section: 'Withdraw Details',
    route: '/move-funds/withdraw',
    page: 'move-funds-withdraw',
    featureFlags: [
      ...DEFAULT_CURRENCY_CLOUD_FLAGS,
      KeepFeatureFlags.CURRENCY_CLOUD_ACCOUNT,
      KeepFeatureFlags.CURRENCY_CLOUD_PAYMENT,
    ],
  },
  {
    section: 'Withdraw Review',
    route: '/move-funds/review',
    page: 'move-funds-review',
    featureFlags: [
      ...DEFAULT_CURRENCY_CLOUD_FLAGS,
      KeepFeatureFlags.CURRENCY_CLOUD_ACCOUNT,
      KeepFeatureFlags.CURRENCY_CLOUD_PAYMENT,
    ],
  },
  {
    section: 'Withdraw Initiated',
    route: '/move-funds/initiated',
    page: 'move-funds-initiated',
    featureFlags: [
      ...DEFAULT_CURRENCY_CLOUD_FLAGS,
      KeepFeatureFlags.CURRENCY_CLOUD_ACCOUNT,
      KeepFeatureFlags.CURRENCY_CLOUD_PAYMENT,
    ],
  },
]

export const CARD_HOLDER_PAGES: PageType[] = [
  { section: 'Home', route: '/', page: 'home' },
  { section: 'Transactions', route: '/transactions', page: 'transactions' },
  { section: 'Cards', route: '/cards', page: 'cards' },
  {
    section: 'Personal information',
    route: '/settings/personal',
    page: 'settings-personal',
  },
  {
    section: 'Notifications',
    route: '/settings/notifications',
    page: 'settings-notifications',
  },
  {
    section: 'Security',
    route: '/settings/security',
    page: 'settings-security',
  },
  {
    section: 'Referrals',
    route: '/refer',
    page: 'referrals',
    icon: <GiftIcon />,
    featureFlags: [StatsigFeatureGate.REFERRALS],
  },
]

export const ACCOUNTANT_PAGES: PageType[] = [
  { section: 'Home', route: '/', page: 'home' },
  { section: 'Cards', route: '/cards', page: 'cards' },
  // transactions pages
  { section: 'Transactions', route: '/transactions', page: 'transactions' },
  {
    section: 'Banking',
    route: '/banking',
    page: 'banking',
    icon: <BankIcon />,
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS],
    accessStatus: [
      CCAccessStatus.NOT_REQUESTED,
      CCAccessStatus.REQUESTED,
      CCAccessStatus.IN_REVIEW,
      CCAccessStatus.APPROVED,
    ],
  },
  {
    section: 'Payments',
    route: '/payments',
    page: 'payments',
    icon: <PaymentsIcon />,
    // for some strange reason you have to modify the dropdown in the PAGES constant
  },
  // settings pages
  {
    section: 'Personal information',
    route: '/settings/personal',
    page: 'settings-personal',
  },
  {
    section: 'Security',
    route: '/settings/security',
    page: 'settings-security',
  },
  {
    section: 'Statements',
    route: '/settings/statements',
    page: 'settings-statements',
  },
  {
    section: 'Accounting',
    route: '/settings/accounting',
    page: 'settings-accounting',
    featureFlags: [StatsigFeatureGate.RUTTER_INTEGRATION],
  },
  {
    section: 'Referrals',
    route: '/refer',
    page: 'referrals',
    icon: <GiftIcon />,
    featureFlags: [StatsigFeatureGate.REFERRALS],
  },
  ...AUTOENROLLMENT_PAGES,
  {
    section: 'Capital',
    route: '/capital',
    page: 'capital',
    icon: <CapitalIcon />,
    rightElement: <NewTag />,
  },
  {
    section: 'Accounting',
    route: '/accounting',
    page: 'accounting',
    icon: (
      <Box w="5">
        <Icon icon="book" variant="inherit" />
      </Box>
    ),
    featureFlags: [StatsigFeatureGate.RUTTER_INTEGRATION],
  },
]

export const CONVERT_CURRENCY_PAGES_V2: PageType[] = [
  {
    section: 'Convert currency',
    route: '/convert-currency/v2',
    page: 'convert-currency',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_CONVERSION],
  },
  {
    section: 'Confirm details',
    route: '/convert-currency/v2/confirm-details',
    page: 'convert-currency-details',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_CONVERSION],
  },
  {
    section: 'Conversion completed',
    route: '/convert-currency/v2/completed',
    page: 'convert-currency-completed',
    featureFlags: [...DEFAULT_CURRENCY_CLOUD_FLAGS, KeepFeatureFlags.CURRENCY_CLOUD_CONVERSION],
  },
]
