import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import { Box, createStylesContext, useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { DateRangePicker, DateRangePickerProps, RangeKeyDict } from 'react-date-range'
import { isValidDate } from 'utils/time'

const [StylesProvider, useStyles] = createStylesContext('DateRangeInput')

export type DateRange = {
  startDate: Date | string | undefined
  endDate: Date | string | undefined
}

export type DateInputRangeProps = Omit<DateRangePickerProps, 'ranges' | 'onChange'> &
  DateRange & {
    isDisabled?: boolean
    displayDefaultRanges?: boolean
    onChange: (dateRange: DateRange) => void
  }

const getVariant = (isDisabled: boolean) => {
  if (isDisabled) {
    return 'disabled'
  }
}

export const DateRangeInput = ({
  displayDefaultRanges = false,
  isDisabled = false,
  direction = 'horizontal',
  months = 1,
  showMonthAndYearPickers = false,
  startDate: unformattedStartDate,
  endDate: unformattedEndDate,
  onChange,
  disabledDay,
  ...props
}: DateInputRangeProps) => {
  const styles = useMultiStyleConfig('DateRangeInput', { variant: getVariant(isDisabled) })
  const startDate = isValidDate(unformattedStartDate) ? new Date(unformattedStartDate) : new Date()
  const endDate = isValidDate(unformattedEndDate) ? new Date(unformattedEndDate) : new Date()
  const ranges = [{ startDate, endDate, key: 'date-ranges' }]

  return (
    <StylesProvider value={styles}>
      <Box
        sx={{
          ...styles.box,
          ...(!displayDefaultRanges && {
            '& .rdrDefinedRangesWrapper': {
              display: 'none',
            },
          }),
          ...(months === 1 && {
            '& .rdrMonthName': {
              display: 'none',
            },
          }),
        }}
      >
        <DateRangePicker
          direction={direction}
          months={months}
          ranges={ranges}
          onChange={(date: RangeKeyDict) => {
            if (isDisabled) {
              return
            }
            const { startDate, endDate } = date['date-ranges']
            onChange({ startDate, endDate })
          }}
          disabledDay={isDisabled ? () => true : disabledDay}
          showMonthAndYearPickers={showMonthAndYearPickers}
          {...props}
        />
      </Box>
    </StylesProvider>
  )
}
