import React, { forwardRef, Ref, useState } from 'react'
import { Box, Center, Divider, Flex, Icon, Progress, Spinner, Tag, Text, useToast } from 'ui-lib'
import { isDefined, Money } from 'utils'
import moment from 'utils/moment'

import { CapitalApprovalVersion, FundingInstallment, useCapitalApprovalQuery, useGetInstallmentsLazyQuery } from '@/gql'
import { FUNDING_STATE_NAMES, FUNDING_STATUS_STYLES } from '@/src/constants/capital'
import { GenericFunding, PartialCapitalFunding } from '@/types/capital'

import { FundingCardDetails } from './funding-card-details'
import { FundingInstallments } from './funding-installments'
import { OriginationFeeFundingCardDetails } from './origination-fee-card-details'

type FundingCardProps = {
  funding: GenericFunding
  defaultOpen?: boolean
  onOpen?: () => void
}

const _FundingCard = ({ funding, defaultOpen, onOpen }: FundingCardProps, ref: Ref<HTMLDivElement>) => {
  const toast = useToast()
  const [open, setOpen] = useState(defaultOpen ?? false)
  const principalAmount = Money.fromNumber(funding.principalAmount.amountAsNumber)

  const { data: query } = useCapitalApprovalQuery()
  const isCapitalPricingVersionV2 = query?.capitalApproval?.pricingVersion === CapitalApprovalVersion.V2

  const totalAmount = funding.totalFee.amountAsNumber + funding.principalAmount.amountAsNumber

  const totalRepayment = Money.fromNumber(funding.totalRepayment.amountAsNumber)

  const [getInstallments, { data, loading, error }] = useGetInstallmentsLazyQuery({
    variables: {
      fundingId: funding.id,
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top',
        duration: 3000,
      })
    },
  })

  const installments = (data?.installments?.filter(Boolean) ?? []) as FundingInstallment[]

  const nextPaymentOn = isDefined(funding.nextPaymentDate) ? moment(funding.nextPaymentDate).utc().format('ll') : null

  const toggleOpen = () => {
    const nextOpen = !open
    setOpen(nextOpen)
    getInstallments()
    if (nextOpen) {
      onOpen?.()
    }
  }

  return (
    <Flex
      ref={ref}
      direction="column"
      width="100%"
      borderRadius="12px"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="border-soft"
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        width="100%"
        py="24px"
        cursor="pointer"
        onClick={toggleOpen}
        px="16px"
        alignItems="center"
        gap={{ base: 3, md: 0 }}
      >
        <Flex width={{ base: '100%', md: '50%' }} direction="row" gap={2}>
          <Flex align="start" mt={1}>
            <Icon icon="chevron-down" size="sm" transform={`${!open ? 'rotate(-90deg)' : 'none'}`} />
          </Flex>
          <Box>
            <Flex gap={2}>
              <Text textStyle="title-md" textColor="text-primary">
                {funding.loanNumber}
              </Text>
              <Tag variant={FUNDING_STATUS_STYLES[funding.status]}>
                <Text textStyle="subheading-sm" textColor="none">
                  {FUNDING_STATE_NAMES[funding.status] ?? funding.status}
                </Text>
              </Tag>
            </Flex>
            {isDefined(nextPaymentOn) && (
              <Text textStyle="paragraph-sm" textColor="text-soft">
                Next payment on {nextPaymentOn}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex
          pl={{
            base: '24px',
            md: 0,
          }}
          width={{ base: '100%', md: '50%' }}
          direction="column"
        >
          <Flex direction="row">
            <Text textStyle="title-md" textColor="text-primary" flexGrow={1}>
              {funding.totalRepayment.amount}
            </Text>
            <Text textStyle="paragraph-lg" textColor="text-secondary" textAlign="right">
              of {funding.principalAmount.amount}
            </Text>
          </Flex>
          <Progress
            colorScheme="primary"
            size="xs"
            value={totalRepayment.toNumber()}
            max={principalAmount.toNumber()}
            marginTop="6px"
            borderRadius="2px"
            backgroundColor="#DDDDDD"
          />
        </Flex>
      </Flex>
      {open &&
        (loading ? (
          <Center m={4}>
            <Spinner size="xl" />
          </Center>
        ) : isDefined(error) ? (
          <Text color="text-error" textStyle="paragraph-md" p={4} ml={6}>
            An error occurred, we could not load the installments.
          </Text>
        ) : (
          <>
            <Divider />
            {isCapitalPricingVersionV2 ? (
              <OriginationFeeFundingCardDetails
                funding={funding as PartialCapitalFunding}
                totalAmount={Money.fromNumber(totalAmount).toFormattedCurrencyString()}
              />
            ) : (
              <FundingCardDetails
                funding={funding}
                totalAmount={Money.fromNumber(totalAmount).toFormattedCurrencyString()}
              />
            )}
            <Divider />
            <FundingInstallments installments={installments} />
          </>
        ))}
    </Flex>
  )
}

export const FundingCard = forwardRef(_FundingCard)
