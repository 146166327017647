import invariant from 'invariant';

// currency/money.ts
var PADDING = 1e4;
var rewardsAsCurrencyStringFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: "CAD",
  currencyDisplay: "narrowSymbol"
});
var rewardsAsPointsStringFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2
});
BigInt.prototype.toJSON = function() {
  return this.toString();
};
var numberToBigInt = (amount) => {
  if (isNaN(amount)) {
    throw new Error("Amount is not a number");
  }
  return BigInt(Math.round(amount * PADDING));
};
var bigIntToNumber = (amount) => {
  return Number(amount / BigInt(PADDING)) + Number(amount % BigInt(PADDING)) / PADDING;
};
var roundBigInt = (value, indexFromLastDigit) => {
  const factor = BigInt(10 ** indexFromLastDigit);
  return BigInt(Math.round(Number(value) / Number(factor))) * factor;
};
var Money = class _Money {
  constructor(amount, currency = "CAD") {
    invariant(typeof amount === "bigint", "Bigint required");
    this._amount = amount;
    if (typeof currency === "string") {
      this._currency = currency;
    } else {
      this._currency = currency.currency;
    }
    this.currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this._currency,
      minimumFractionDigits: 2,
      currencyDisplay: "narrowSymbol"
    });
    Object.defineProperty(this, "currencyFormatter", {
      enumerable: false
    });
  }
  static fromMoney(other) {
    return new _Money(other._amount, other._currency);
  }
  static fromNumber(amount, currency = "CAD") {
    return new _Money(numberToBigInt(amount), currency);
  }
  static fromBigInt(amount, currency = "CAD") {
    return new _Money(amount, currency);
  }
  static fromFormattedCurrencyString(formattedString, currency = "CAD") {
    const amountString = this.removeNonDigits(formattedString);
    const value = parseFloat(amountString.replace(/,/g, "")) || 0;
    return _Money.fromBigInt(numberToBigInt(value), currency);
  }
  static fromRewardsPointsString(formattedString) {
    const value = parseFloat(formattedString.replace(/,/g, "").replace("$", "")) || 0;
    return _Money.fromBigInt(numberToBigInt(value));
  }
  static fromRewardsPointsNumber(points) {
    return _Money.fromBigInt(numberToBigInt(points / 100));
  }
  static fromString(stringValue, currency) {
    const numberAmount = parseFloat(stringValue);
    if (isNaN(numberAmount)) {
      throw new Error(`Failed to parse value '${stringValue}' is NaN.`);
    }
    return this.fromNumber(numberAmount, currency);
  }
  static removeNonDigits(str) {
    return str.replace(/[^\d,.-]/g, "");
  }
  add(other) {
    invariant(other._currency === this._currency, `Currencies must be equal. ${other._currency} !== ${this._currency}`);
    return new _Money(this._amount + other._amount, this._currency);
  }
  addMany(others) {
    const total = others.reduce((acc, other) => {
      invariant(
        other._currency === this._currency,
        `Currencies must be equal. ${other._currency} !== ${this._currency}`
      );
      return acc.add(other);
    }, this);
    return total;
  }
  sub(other) {
    invariant(other._currency === this._currency, "Currencies must be equal");
    return new _Money(this._amount - other._amount, this._currency);
  }
  /**
   * Rounds to the nearest cent. If the decimal is .5 or greater, it rounds up.
   * If the decimal is less than .5, it rounds down.
   *
   * @param factor
   * @returns
   */
  mul(factor) {
    if (typeof factor === "number") {
      invariant(factor >= 0, "Factor must be positive");
      const bigIntAsString = this.amount().toString();
      const bigIntAsNumber = Number.parseInt(bigIntAsString);
      const product = bigIntAsNumber * factor;
      let value = numberToBigInt(product / PADDING);
      const round = value % BigInt(100);
      if (round > BigInt(0)) {
        if (round >= BigInt(50)) {
          value += BigInt(100) - round;
        } else {
          value -= round;
        }
      }
      return new _Money(value, this._currency);
    }
    return new _Money(this._amount * factor, this._currency);
  }
  div(other) {
    return new _Money(this._amount / other, this._currency);
  }
  percentage(percentage) {
    invariant(percentage >= 0, "Percentage must be positive");
    return this.mul(percentage / 100);
  }
  amount() {
    return this._amount;
  }
  clone() {
    return new _Money(this._amount, this._currency);
  }
  toJSON() {
    return this._amount.toString();
  }
  get currency() {
    return this._currency;
  }
  toNumber() {
    const cents = BigInt(this._amount / BigInt(100));
    const dollars = Number(cents) / 100;
    return dollars;
  }
  toFloatString() {
    return String(this.toNumber());
  }
  /**
   * Formats the Money with the currency symbol.
   * Outputs: $14,232.99
   * @returns Formatted string with currency symbol
   */
  toFormattedCurrencyString() {
    return this.currencyFormatter.format(this.toNumber());
  }
  abs() {
    return new _Money(this._amount > BigInt(0) ? this._amount : this._amount * BigInt(-1), this._currency);
  }
  convert(currencyPair) {
    if (currencyPair.from === this._currency) {
      return new _Money(this.mul(currencyPair.rate).amount(), currencyPair.to);
    } else if (currencyPair.to === this._currency) {
      return new _Money(this.mul(currencyPair.inverseRate).amount(), currencyPair.from);
    } else {
      throw new Error(
        `Currency pair ${currencyPair.from}${currencyPair.to} does not contain currency ${this._currency}.`
      );
    }
  }
  toRewardsString() {
    return rewardsAsCurrencyStringFormatter.format(Number(this._amount) / 1e4);
  }
  toRewardsAsPointsString() {
    return rewardsAsPointsStringFormatter.format(Number(this._amount) / 100);
  }
  toRewardsAsPointsNumber() {
    return Number(this._amount) / 100;
  }
  toCents() {
    return this._amount * BigInt(100) / BigInt(PADDING);
  }
  equals(other) {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`);
    return this._amount === other._amount;
  }
  lessThan(other) {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`);
    return this._amount < other._amount;
  }
  lessThanEqual(other) {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`);
    return this._amount <= other._amount;
  }
  greaterThan(other) {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`);
    return this._amount > other._amount;
  }
  greaterThanEqual(other) {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`);
    return this._amount >= other._amount;
  }
  isNegativeOrZero() {
    return this._amount <= 0;
  }
  isNegative() {
    return this._amount < 0;
  }
  isPositive() {
    return this._amount > 0;
  }
};

export { Money, PADDING, bigIntToNumber, numberToBigInt, roundBigInt };
