import invariant from 'invariant'

import { type CurrencyCode } from './codes'
import { CurrencyPair } from './currency-pair'

export const PADDING = 10_000

const rewardsAsCurrencyStringFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: 'CAD',
  currencyDisplay: 'narrowSymbol',
})
const rewardsAsPointsStringFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
})

//@ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString()
}

/*
 * Converts given amount to a bigint padding to 4 zeros after decimal
 */
export const numberToBigInt = (amount: number): bigint => {
  if (isNaN(amount)) {
    throw new Error('Amount is not a number')
  }
  return BigInt(Math.round(amount * PADDING))
}

/*
 * Converts given bigint to a number removing padding
 */
export const bigIntToNumber = (amount: bigint): number => {
  return Number(amount / BigInt(PADDING)) + Number(amount % BigInt(PADDING)) / PADDING
}

// A Wallet has a `currency` field of type CurrencyCode
export type CurrencyType = CurrencyCode | { currency: CurrencyCode }

export const roundBigInt = (value: bigint, indexFromLastDigit: number) => {
  const factor = BigInt(10 ** indexFromLastDigit)
  return BigInt(Math.round(Number(value) / Number(factor))) * factor
}

/**
 * Represents rewards points / money.
 */
export class Money {
  private _currency: CurrencyCode
  private _amount: bigint
  private currencyFormatter: Intl.NumberFormat
  private constructor(amount: bigint, currency: CurrencyType = 'CAD') {
    invariant(typeof amount === 'bigint', 'Bigint required')
    this._amount = amount
    if (typeof currency === 'string') {
      this._currency = currency
    } else {
      this._currency = currency.currency
    }
    this.currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this._currency,
      minimumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol',
    })
    Object.defineProperty(this, 'currencyFormatter', {
      enumerable: false,
    })
  }
  static fromMoney(other: Money) {
    return new Money(other._amount, other._currency)
  }
  static fromNumber(amount: number, currency: CurrencyType = 'CAD') {
    return new Money(numberToBigInt(amount), currency)
  }
  static fromBigInt(amount: bigint, currency: CurrencyType = 'CAD') {
    return new Money(amount, currency)
  }
  static fromFormattedCurrencyString(formattedString: string, currency: CurrencyType = 'CAD') {
    const amountString = this.removeNonDigits(formattedString)
    const value = parseFloat(amountString.replace(/,/g, '')) || 0
    return Money.fromBigInt(numberToBigInt(value), currency)
  }
  static fromRewardsPointsString(formattedString: string) {
    const value = parseFloat(formattedString.replace(/,/g, '').replace('$', '')) || 0
    return Money.fromBigInt(numberToBigInt(value))
  }
  static fromRewardsPointsNumber(points: number) {
    return Money.fromBigInt(numberToBigInt(points / 100))
  }
  static fromString(stringValue: string, currency?: CurrencyCode) {
    const numberAmount = parseFloat(stringValue)
    if (isNaN(numberAmount)) {
      throw new Error(`Failed to parse value '${stringValue}' is NaN.`)
    }
    return this.fromNumber(numberAmount, currency)
  }
  private static removeNonDigits(str: string): string {
    // Remove any characters that aren't digits or ,-.
    return str.replace(/[^\d,.-]/g, '')
  }
  add(other: Money) {
    invariant(other._currency === this._currency, `Currencies must be equal. ${other._currency} !== ${this._currency}`)
    return new Money(this._amount + other._amount, this._currency)
  }
  addMany(others: Money[]): Money {
    const total = others.reduce((acc, other) => {
      invariant(
        other._currency === this._currency,
        `Currencies must be equal. ${other._currency} !== ${this._currency}`
      )
      return acc.add(other)
    }, this)
    return total
  }
  sub(other: Money) {
    invariant(other._currency === this._currency, 'Currencies must be equal')
    return new Money(this._amount - other._amount, this._currency)
  }
  /**
   * Rounds to the nearest cent. If the decimal is .5 or greater, it rounds up.
   * If the decimal is less than .5, it rounds down.
   *
   * @param factor
   * @returns
   */
  mul(factor: bigint | number) {
    if (typeof factor === 'number') {
      invariant(factor >= 0, 'Factor must be positive')
      const bigIntAsString = this.amount().toString()
      const bigIntAsNumber = Number.parseInt(bigIntAsString)
      const product = bigIntAsNumber * factor
      let value = numberToBigInt(product / PADDING)
      const round = value % BigInt(100)
      if (round > BigInt(0)) {
        if (round >= BigInt(50)) {
          value += BigInt(100) - round
        } else {
          value -= round
        }
      }
      return new Money(value, this._currency)
    }
    return new Money(this._amount * factor, this._currency)
  }
  div(other: bigint) {
    return new Money(this._amount / other, this._currency)
  }
  percentage(percentage: number) {
    invariant(percentage >= 0, 'Percentage must be positive')
    return this.mul(percentage / 100)
  }
  amount() {
    return this._amount
  }
  clone() {
    return new Money(this._amount, this._currency)
  }
  toJSON() {
    return this._amount.toString()
  }
  get currency() {
    return this._currency
  }
  toNumber() {
    // Removes the last two digits
    const cents = BigInt(this._amount / BigInt(100))
    // Moves decimal two to the left
    const dollars = Number(cents) / 100
    return dollars
  }
  toFloatString() {
    return String(this.toNumber())
  }
  /**
   * Formats the Money with the currency symbol.
   * Outputs: $14,232.99
   * @returns Formatted string with currency symbol
   */
  toFormattedCurrencyString() {
    return this.currencyFormatter.format(this.toNumber())
  }
  abs() {
    return new Money(this._amount > BigInt(0) ? this._amount : this._amount * BigInt(-1), this._currency)
  }
  convert(currencyPair: CurrencyPair) {
    if (currencyPair.from === this._currency) {
      return new Money(this.mul(currencyPair.rate).amount(), currencyPair.to)
    } else if (currencyPair.to === this._currency) {
      return new Money(this.mul(currencyPair.inverseRate).amount(), currencyPair.from)
    } else {
      throw new Error(
        `Currency pair ${currencyPair.from}${currencyPair.to} does not contain currency ${this._currency}.`
      )
    }
  }
  toRewardsString() {
    return rewardsAsCurrencyStringFormatter.format(Number(this._amount) / 10000)
  }
  toRewardsAsPointsString() {
    return rewardsAsPointsStringFormatter.format(Number(this._amount) / 100)
  }
  toRewardsAsPointsNumber() {
    return Number(this._amount) / 100
  }
  toCents(): bigint {
    // Remove padding (=original value) then multiply by 100 (=cents)
    return (this._amount * BigInt(100)) / BigInt(PADDING)
  }
  equals(other: Money): boolean {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`)
    return this._amount === other._amount
  }
  lessThan(other: Money): boolean {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`)
    return this._amount < other._amount
  }
  lessThanEqual(other: Money): boolean {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`)
    return this._amount <= other._amount
  }
  greaterThan(other: Money): boolean {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`)
    return this._amount > other._amount
  }
  greaterThanEqual(other: Money): boolean {
    invariant(this.currency === other.currency, `Currencies do not match: ${this.currency} !== ${other.currency}`)
    return this._amount >= other._amount
  }
  isNegativeOrZero(): boolean {
    return this._amount <= 0
  }
  isNegative(): boolean {
    return this._amount < 0
  }
  isPositive(): boolean {
    return this._amount > 0
  }
}
