import { useState } from 'react'
import { DEFAULT_COUNTRY } from 'utils'

import { GenericAddressData } from '@/types/form-info'

/**
 *
 * @param isPrefilled Force to fill the address with the fillableAddress
 * @param fillableAddress Address to fill
 * @param initialAddress Address to use as default
 * @returns
 */
export const useFillableAddress = (
  isPrefilled: boolean,
  fillableAddress?: GenericAddressData,
  initialAddress?: GenericAddressData
) => {
  const [isFilled, setIsFilled] = useState(isPrefilled)

  const defaultAddress: GenericAddressData | undefined = initialAddress ?? {
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: DEFAULT_COUNTRY,
    postalCode: '',
    state: '',
  }

  return {
    defaultAddress,
    fillableAddress,
    isFilled,
    setIsFilled,
  }
}
