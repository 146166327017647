import React from 'react'
import { Alert, AlertDescription, AlertTitle, Icon, Text } from 'ui-lib'
import { isDefined } from 'utils'

import { useDelinquencyAmountQuery } from '@/gql'

export const DelinquencyBanner = () => {
  const { data, loading, error } = useDelinquencyAmountQuery()

  if (loading) {
    return null
  }

  if (isDefined(error) || !isDefined(data?.delinquencyAmount?.amount)) {
    return null
  }

  return (
    <Alert
      variant="negative"
      mb="6"
      size="md"
      icon={<Icon icon="circle-alert-fill" w={8} h={8} mr={2} variant="inherit" />}
    >
      <AlertTitle>Your recent payment to Keep is overdue.</AlertTitle>
      <AlertDescription>
        Failure to pay your dues of {data?.delinquencyAmount?.amount} will lead to the blocking of your Keep account.
        Please contact{' '}
        <Text as="span" color="text-error" textStyle="paragraph-md" fontWeight="semibold" textDecoration="underline">
          legal@trykeep.com
        </Text>{' '}
        to settle your outstanding balance.
      </AlertDescription>
    </Alert>
  )
}
