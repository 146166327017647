import { createContext, useContext, useEffect, useState } from 'react'
import { isDefined } from 'ui-lib'

import { logger } from '@/src/logging/logger'

export const RecaptchaContext = createContext<{
  execute: ExecuteFunction | undefined
}>({
  execute: undefined,
})

declare global {
  interface Window {
    grecaptcha: any
  }
}

export type RecaptchaProviderProps = {
  siteKey: string
  children: string | JSX.Element | JSX.Element[]
}

type ExecuteFunction = (action: string) => Promise<string>

const SCRIPT_ID = 'recaptcha-script'
let executeFunction: ExecuteFunction | undefined = undefined

export const RecaptchaProvider = (props: RecaptchaProviderProps) => {
  const [execute, setExecute] = useState<ExecuteFunction | undefined>(() => executeFunction)

  useEffect(() => {
    const existingScript = document.getElementById(SCRIPT_ID)

    if (!isDefined(existingScript)) {
      const script = document.createElement('script')
      const handleLoaded = () => {
        logger.info('recaptcha loaded')
        window.grecaptcha.ready(() => {
          logger.info('recaptcha ready')
          executeFunction = (action: string) => window.grecaptcha.execute(props.siteKey, { action })
          setExecute(() => executeFunction)
        })
      }

      script.src = `https://www.google.com/recaptcha/api.js?render=${props.siteKey}`
      script.addEventListener('load', handleLoaded)
      script.id = SCRIPT_ID
      document.body.appendChild(script)
    }

    document.querySelectorAll('.grecaptcha-badge').forEach((e) => e.removeAttribute('hidden'))

    return () => {
      document.querySelectorAll('.grecaptcha-badge').forEach((e) => e.setAttribute('hidden', 'true'))
    }
  }, [props.siteKey])

  return <RecaptchaContext.Provider value={{ execute }}>{props.children}</RecaptchaContext.Provider>
}

export const useRecaptcha = () => {
  return useContext(RecaptchaContext)
}
