import { ConstToUnion } from 'utils/types'

export const AUTH_PAGE_REDIRECT_TO = {
  NONE: 'NONE',
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  APPLICATION: 'APPLICATION',
  APPLICATION_V2: 'APPLICATION_V2',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  MISSING_INFORMATION: 'MISSING_INFORMATION',
  SUBMITTED: 'SUBMITTED',
  SUBMITTED_V2: 'SUBMITTED_V2',
  DENIED: 'DENIED',
  ONBOARDING: 'ONBOARDING',
  RELINK_PLAID_BUSINESS: 'RELINK_PLAID_BUSINESS',
  RELINK_PLAID_APPLICATION: 'RELINK_PLAID_APPLICATION',
  INVITATION_SUBMITTED: 'INVITATION_SUBMITTED',
  INVITATION_REJECTED: 'INVITATION_REJECTED',
  SCREENING_REJECTED: 'SCREENING_REJECTED',
} as const

export const AUTH_PROVIDERS = {
  CREDENTIALS: 'credentials',
  GOOGLE: 'google',
  TWO_FACTOR: '2fa',
} as const

export type AuthPageAction = ConstToUnion<typeof AUTH_PAGE_REDIRECT_TO>
