import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { useActiveIncidentNotificationsQuery } from '@/gql'

export const useIncidentNotifications = () => {
  const toast = useToast()
  const { pathname } = useRouter()
  const { status } = useSession()
  const { data } = useActiveIncidentNotificationsQuery({ skip: status !== 'authenticated' })
  const [displayedErrors, setDisplayedErrors] = useState<string[]>([])

  useEffect(() => {
    if (isDefined(data) && isDefined(data.activeIncidentNotifications)) {
      for (const incidentNotification of data.activeIncidentNotifications) {
        if (
          isDefined(incidentNotification) &&
          (incidentNotification.pages === pathname || incidentNotification.pages === '/*')
        ) {
          const { message, severity } = incidentNotification
          if (!displayedErrors.includes(message)) {
            toast({
              title: message,
              status: severity.toLowerCase() as 'loading' | 'info' | 'warning' | 'success' | 'error',
              position: 'top',
              isClosable: true,
              duration: null,
            })
            setDisplayedErrors((errors) => [...errors, message])
          }
        }
      }
    }
  }, [data, pathname, toast, displayedErrors])
}
