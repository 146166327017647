import Image from 'next/image'
import { Banner, Box, Button, Card, Flex, Icon, ListIcon, ListItem, Text, UnorderedList } from 'ui-lib'
import { isDefined } from 'utils'

import {
  CapitalApprovalVersion,
  useCapitalApprovalQuery,
  useCreateCapitalRequestMutation,
  useGetCapitalInfoQuery,
} from '@/gql'
import { SEGMENT_EVENTS } from '@/src/constants/segmentEvents'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useEffectOnce, useServerTrackEvent } from '@/src/hooks/misc'
import { date } from '@/src/utils/date'
import { useInternalUserStatus } from '@/src/utils/user-session'

export const CapitalOnboarding = () => {
  const [createRequest, { loading: isCreatingRequest }] = useCreateCapitalRequestMutation()
  const { data: capitalInfoData } = useGetCapitalInfoQuery()

  const { data } = useCapitalApprovalQuery()
  const isCapitalPricingVersionV2 = data?.capitalApproval?.pricingVersion === CapitalApprovalVersion.V2

  const trackServerEvent = useServerTrackEvent()
  const lastRequestedAt = capitalInfoData?.capitalInfo?.capitalRequest?.createdAt
  const { userBusinessIsActive } = useInternalUserStatus()

  const has30DaysPassed = date().isAfter(date(lastRequestedAt).add(30, 'day'))
  const canRequestCapital = !isDefined(lastRequestedAt) || has30DaysPassed
  const isCTADisabled = [canRequestCapital, userBusinessIsActive].some((value) => !value)

  useEffectOnce(() => {
    if (isCapitalPricingVersionV2) {
      trackServerEvent({
        type: SEGMENT_PAGE_VISITED.CAPITAL_HOME_PAGE_VISITED,
        payload: {
          team: 'Capital',
          page: 'Capital home page - no credit approval',
          total_credit_limit: '0',
          version: 'v2',
        },
      })
    }
  })

  const onContactSupport = () => {
    if (!userBusinessIsActive) {
      return
    }

    createRequest({
      onCompleted: () => {
        trackServerEvent({
          type: SEGMENT_EVENTS.CONTACT_SUPPORT_INITIATED,
          payload: {
            team: 'Capital',
          },
        })
      },
      refetchQueries: ['GetCapitalInfo'],
    })
  }

  return (
    <>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={8}
        mt={4}
        display={{ base: 'none', md: 'flex' }}
      >
        <Text
          textStyle="headline-3"
          as="h1"
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          Capital
        </Text>
      </Flex>

      {!canRequestCapital && (
        <Banner size="sm" variant="success" mb="6">
          <Banner.Image src="/capital/clock.jpg" />
          <Banner.Headline>Thank you for your interest in Capital.</Banner.Headline>
          <Banner.Content>Our support team has received your request and will reach out to you soon.</Banner.Content>
        </Banner>
      )}

      <Card p="6" display="flex" flexDir="row" justifyContent="space-between" position="relative">
        <Flex flexDir="column" justifyContent="space-evenly" gap="6">
          <Box>
            <Text
              textStyle="headline-4"
              mb="1"
              sx={{
                background: 'gradient.blueGreen',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Get Capital to Grow Faster
            </Text>
            <Text color="text-secondary">Join hundreds of businesses growing with Keep Capital.</Text>
          </Box>
          <UnorderedList
            border="0"
            p="0"
            styleType="none"
            _hover={{
              bg: 'transparent',
            }}
            m="0"
            spacing="2"
          >
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              Up to $200,000 in Capital Financing
            </ListItem>
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              Approvals within 72 hours
            </ListItem>
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              No-dilution or hidden fees
            </ListItem>
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              Clear & transparent terms
            </ListItem>
          </UnorderedList>

          <Button
            onClick={onContactSupport}
            isLoading={isCreatingRequest}
            isDisabled={isCTADisabled}
            width="min-content"
          >
            {canRequestCapital ? 'Contact support' : 'Request submitted'}
          </Button>
        </Flex>

        <Box
          height="288px"
          width="auto"
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <Image
            width={0}
            height={0}
            style={{
              width: 'auto',
              height: '100%',
            }}
            sizes="100vw"
            src="/capital/onboarding.png"
            alt=""
          />
        </Box>
      </Card>
    </>
  )
}
